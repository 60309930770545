import request from '@/assets/javascript/request'

export default {
  /**
   * 获取小组成员
   */
  getMessageList(params) {
    return request.get('/message/index', params)
  },
  /**
   * 单个已读
   */
  readMessage(params) {
    return request.post('/message/read', params)
  },
  /**
   * 一键已读
   */
  readAllMessage(params) {
    return request.post('/message/allRead', params)
  },
}
