<template>
  <el-dialog
    :visible.sync="visible"
    :close-on-click-modal="false"
    :title="msgDialog ? msgDialog.title : ''"
    top="40vh"
    :width="msgDialog ? msgDialog.width : '439px'">
	<div class="flex jc_s message-main-body ai_c">
		<i v-if="msgDialog && msgDialog.type" :class="`icon__${msgDialog.type}`" class="main-icon" />
		<div class="msg-dialog-message">
			<p class="message" v-html="msgDialog ? msgDialog.message : ''"></p>
			<p class="tip" v-if="msgDialog && msgDialog.tip">{{ msgDialog ? msgDialog.tip : '' }}</p>
		</div>		
	</div>    
    <div class="flex jc_e ai_c pt20">
		<t-btn
			w="78px"
			h="36px"
			fs="14px"
			type="info"
			@click="close" 
			class="bold">取消</t-btn>
		<t-btn
		  @click="commit"
		  w="78px"
		  h="36px"
		  fs="14px"
		  class="bold ml10">确认</t-btn>
    </div>
  </el-dialog>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapState({
      msgDialog: state => state.msgDialog
    }),
    visible: {
      get() {
        const { msgDialog } = this

        if (msgDialog) {
          return true
        } else {
          return false
        }
      },
      set(val) {
        if (!val) {
          this.SETMSGDIALOG(null)
        }
      }
    },
  },
  methods: {
    ...mapMutations(['SETMSGDIALOG']),
    close() {
      this.SETMSGDIALOG(null)
    },
    commit() {
      const { msgDialog } = this

      msgDialog.commit && msgDialog.commit()
    }
  }
}
</script>

<style lang="scss" scoped>
	.el-dialog{		
		.msg-dialog-message {
			font-size: 16px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #333333;
			p{				
				line-height: 25px;
				font-size: 14px;
			}
			.tip{
				color: #F4A93D;
			}
		}
		.message-main-body{
			margin-bottom: 24px;
			
			.main-icon {
			  width: 36px;
			  height: 36px;
			  margin-right: 10px;
			  &.icon__success {
			    background: url('~@/assets/image/global/popup_img_success.png') no-repeat;
			    background-size: 100%;
			  }
			  &.icon__error {
			    background: url('~@/assets/image/global/popup_img_error.png') no-repeat;
			    background-size: 100%;
			  }
			}
		}
		.button-container{
			&.text{
				width: 78px!important;
				line-height: 36px!important;
				height: 36px!important;
				background: #F2F2F2!important;
				border-radius: 8px!important;
				color: #666666!important;
				text-align: center;
			}
			&.btn__primary{
				border-radius: 8px!important;			
			}
		}
	}
</style>
