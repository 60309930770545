import settings from '@/settings'

export default {
  data() {
    return {
      NOT: settings.NOT,
      list: [],
      pages: null,
      pageParams: {
        page: 1,
        perPage: 10
      }
    }
  },
  methods: {
    // 页码改变
    currentChange(current) {
      this.pageParams.page = current

      this.getList()
    },
    // 页数改变
    sizeChange(size) {
      this.pageParams.perPage = size

      this.getList()
    }
  }
}
