<template>
  <div v-if="loading" class="message-container">
    <div @click.stop class="message-main">
      <i class="main-icon" />
      <p class="main-messgse">{{ loading.title }}</p>
      <span class="main-tip" v-html="loading.message"></span>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapState({
      loading: state => state.loading
    })
  },
  watch: {
    loading(val) {
      if (val) {
        document.body.style.overflow = 'hidden'
        document.removeEventListener('touchmove', this.mo, false)
      } else {
        document.body.style.overflow = ''
        document.removeEventListener('touchmove', this.mo, false)
      }
    }
  },
  methods: {
    ...mapMutations(['SETLOADING']),
    mo(e) {
    	e.preventDefault()
    },
    close() {
      this.SETLOADING(false)
    }
  }
}
</script>

<style lang="scss" scoped>
  .message-container {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    left: 0;
    top: 0;
    z-index: 10000;
    .message-main {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 300px;
      max-width: 400px;
      padding: 40px 30px 30px;
      background: #fff;
      box-shadow: 0px 2px 16px 0px rgba(188, 188, 188, 0.38);
      text-align: center;
      left: 50%;
      top: 30%;
      transform: translateX(-50%);	  
	 border-radius: 8px;
      .el-icon-close {
        position: absolute;
        font-size: 16px;
        top: 12px;
        right: 12px;
      }
      .main-icon {
        width: 40px;
        height: 40px;
        margin-bottom: 10px;
        // background: url('~@/assets/image/global/popup_img_paying.png') no-repeat;
        background: url('~@/assets/image/global/popup_img_paying.gif') no-repeat;
        background-size: 100%;
      }
      .main-messgse {
        max-width: 100%;
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        color: $primary;       
        text-align: center;
        margin-left: 18px;
      }
      .main-tip {
        max-width: 100%;
        margin-top: 6px;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        color: $primary_text;
        text-align: justify;
      }
    }
  }
</style>
