/**
 * 判断有效 手机号码
 * @method isMobile
 * @param {String} val 手机号
 */
export function isMobile(val) {
  return /1[3456789][0-9]{9}$/.test(val)
}
/**
 * 判断email地址
 * @method isEmail
 * @param {String} val
 */
export function isEmail(val) {
  return /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(val)
}
/**
 * 金钱格式化
 * @method formatMoney
 * @param {String,Number} money 目标金钱
 * @return 金钱格式
 */
export function formatMoney(money) {
  if (money !== undefined) {
    const str = parseFloat(money).toFixed(2).toString()
    const intSum = str.substring(0, str.indexOf('.')).replace(/\B(?=(?:\d{3})+$)/g, ',')
    const dot = str.substring(str.length, str.indexOf('.'))
    const ret = intSum + dot
    return ret
  }
  return 0
}
/**
 * 下载
 */
export function download(url, filename) {
  const x = new XMLHttpRequest()
  x.open("GET", url, true)
  x.responseType = 'blob'
  x.onload = function () {
    const href = window.URL.createObjectURL(x.response)
    const a = document.createElement('a')
    a.href = href
    a.download = filename
    a.click()
  }
  x.send()
}
/**
 * blob
 */
export function dataURLtoBlob(dataurl) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], {
    type: mime
  });
}
/**
 * file
 */
export function dataURLtoFile(dataurl, filename) { //将base64转换为文件
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {
    type: mime
  });
}

export function copyText(text,callBack) {
  const input = document.createElement("input");
  document.body.appendChild(input);
  input.setAttribute("value", text);
  input.select();
  if (document.execCommand("copy")) {
    document.execCommand("copy");
    callBack&&callBack()
  }
  document.body.removeChild(input);
}