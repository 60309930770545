<!--
 * Description: 新建案件弹窗
-->

<template>
    <el-dialog @closed="closed" :visible.sync="visible" :append-to-body="true" width="564px" top="25vh" title="新建案件">
        <el-form :model="form" :rules="rules" ref="form" class="case-form" label-width="102px" @submit.native.prevent
            label-position="right" size="medium">
            <el-form-item label="案件类型" prop="activeType" class="required-item-label">
                <div class="type-list">
                    <el-tag v-for="(type, index) in types" :key="'tag_' + index" :type="index == activeType ? '' : 'info'"
                        @click="typeSelect(type, index)">
                        {{ type.name }}
                    </el-tag>
                </div>
            </el-form-item>
            <div v-if="activeType == 0">
                <el-form-item v-if="activeType == 0" label="分类" prop="criminal" class="required-item-label">
                    <el-radio-group v-model="criminal">
                        <el-radio v-for="item in criminals" :key="'criminal_' + item.id" :label=item.id>
                            {{ item.name }}
                        </el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item v-if="nowCriminalIndex == 0" label="年份" class="required-item-label">
                    <el-popover v-model="yearPopover" popper-class="year-select-box" placement="bottom" trigger="click">
                        <div class="year-select-main">
                            <div v-for="year in years" class="year-item" @click="yearSelect(year)">
                                {{ year.name }}
                            </div>
                        </div>
                        <div class="year-create-box">
                            <el-input placeholder="请输入年份" v-model="newYear" maxlength="4">
                                <template slot="append">
                                    <el-button type="primary" @click="addNewYear">添加年份</el-button>
                                </template>
                            </el-input>
                        </div>
                        <div slot="reference" class="year-select-trigger">
                            <span v-if="!selectdYear" style="color:#b2b2b2">请选择年份</span>
                            <span v-else>{{ selectdYear }}</span>
                        </div>
                    </el-popover>
                    <!-- <el-select 
                        v-model="form.parentId" 
                        placeholder="请选择年份"
                        @change="yearChange">
                        <el-option
                            v-for="item in years"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select> -->
                </el-form-item>
                <el-form-item label="默认排序" prop="selectdYear" class="required-item-label">
                    <el-input :value="sortNumber" :disabled="true" class="input-width" maxlength="70" />
                </el-form-item>
                <el-form-item :label=humanLabel prop="human" class="required-item-label">
                    <el-input v-model="form.human" class="input-width" :placeholder="humanPlaceholder" maxlength="70" />
                </el-form-item>
                <el-form-item v-if="nowCriminalIndex == 2" label="项目名称" prop="name" class="required-item-label">
                    <el-input v-model="form.name" class="input-width" placeholder="请输入项目名称" maxlength="70" />
                </el-form-item>
                <el-form-item v-else label="罪名" prop="name" class="required-item-label">
                    <el-select v-model="form.name" placeholder="请输入罪名" multiple filterable>
                        <el-option v-for="item in crimeItems" :key="item.id" :label="item.name" :value="item.name">
                        </el-option>
                    </el-select>
                </el-form-item>
            </div>
            <div v-else>
                <el-form-item label="新建文件夹" class="required-item-label">
                    <el-input v-model="folderName" class="input-width" placeholder="请填写文件夹名称" maxlength="70" />
                </el-form-item>
            </div>
        </el-form>
        <div class="flex jc_e ai_c pt20">
            <t-btn w="78px" h="36px" fs="14px" type="info" @click="visible = false" class="bold">取消</t-btn>
            <t-btn @click="commit" w="78px" h="36px" fs="14px" class="bold ml10">确认</t-btn>
        </div>
    </el-dialog>
</template>

<script>
import store from '@/store'
export default {
    name: "newCase",
    props: {
        image: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            visible: false,
            types: null,
            criminals: null,
            activeType: 0,
            yearPopover: false,    //年份选择是否显示
            selectdYear: "",        //被选中的年份
            newYear: "",           //下拉框输入年份
            years: [],
            params: {
                name: '',
                type: 0
            },
            rules: {
                activeType: [
                    { required: true, message: '请选择案卷类型', trigger: "change" },
                ],
                name: [
                    { required: true, message: '请选择罪名', trigger: "change" },
                ],
                human: [
                    { required: true, message: "请输入嫌疑人姓名", trigger: "blur" },
                ],
            },
            folderName: "",
            criminal: 123,             //案卷分类
            caseNumber: "",            //年份下排序
            reportNumber: "",              //
            accusingNumber: "",            //非诉文件夹名
            crimeItems: [],            //罪名列表
            form: {
                activeType: "",            //案卷类型
                parentId: "",          //年份文件夹ID
                human: "",             //嫌疑人姓名
                name: "",           //罪名
            }
        }
    },
    computed: {
        nowCriminalIndex() {
            if (!this.criminals || this.criminals.length == 0) return -1;
            let index = 0;
            for (let i = 0; i < this.criminals.length; i++) {
                let c = this.criminals[i];
                if (c.id == this.criminal) {
                    index = i;
                    break;
                }
            }
            return index
        },
        sortNumber() {
            if (this.nowCriminalIndex < 0) return false;
            return this[["caseNumber", "reportNumber", "accusingNumber"][this.nowCriminalIndex]]
        },
        humanLabel() {
            if (this.nowCriminalIndex < 0) return false;
            return ["嫌疑人", "被害人", "委托人"][this.nowCriminalIndex]
        },
        humanPlaceholder() {
            if (this.nowCriminalIndex < 0) return false;
            let text = "请输入"
            text += ["嫌疑人", "被害人", "委托人"][this.nowCriminalIndex]
            text += "姓名"
            return text
        }
    },
    watch: {
        nowCriminalIndex(val) {
            if (val == 2) {
                this.$set(this.form, "name", "")
            } else {
                this.$set(this.form, "name", [])
            }
        },
        "$store.state.curGroupId"(gid) {         //小组切换刷新界面
            this.selectdYear = "";
            this.init();
        },
        "$store.state.yearChange"(val) {             //辩护年份增删修改响应
            if (val) {
                this.init();
            }
        },
        criminal(criminal) {         //刑事案件-分类切换
            let index = 0;
            for (let i = 0; i < this.criminals.length; i++) {
                let c = this.criminals[i];
                if (c.id == criminal) {
                    index = i;
                    break;
                }
            }
            let text = `请输入${["嫌疑人", "被害人", "委托人"][index]}姓名`
            this.$set(this.rules, "human", [
                { required: true, message: text, trigger: "blur" },
            ])
            this.$nextTick(() => {
                this.$refs.form && this.$refs.form.clearValidate();
            })
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.$api.cases.fastCreateEntry().then(res => {
                const { items, parent, year } = res;
                let arr = parent.filter(p => ![92, 93].includes(p.typeId))            //私人文件夹和事务文件夹不用
                this.$set(this, "types", arr)

                arr = items.filter(i => ![88].includes(i.typeId))            //文书模板不用快速创建
                this.$set(this, "criminals", arr)
                this.$set(this, "criminal", arr[0].id)

                this.$set(this, "years", year);
                this.form.parentId = year[0].id;
            })

            this.$api.system.getGlobalList({
                type: 'crimeItems'
            }).then(res => {
                this.crimeItems = res.crimeItems || []
            })
        },
        show() {
            this.visible = true;
            this.setFolderNumber();
        },
        setFolderNumber() {
            const _this = this;
            let $request = this.$api.cases.getCaseNumber
            for (let i = 0; i < this.criminals.length; i++) {
                let c = this.criminals[i];
                (function (c, index) {
                    $request({ parentId: c.id }).then(res => {           //报案文件夹排序
                        if (index == 1) {
                            _this.reportNumber = res.tag + '-' + res.number
                        } else if (index == 2) {
                            _this.accusingNumber = res.tag + '-' + res.number
                        }
                    })
                })(c, i)
            }
            // this.$api.cases.getCaseNumber({ parentId:this.criminal }).then(res => {           //报案文件夹排序
            //     this.reportNumber = res.tag + '-' + res.number
            // })
            // this.$api.cases.getCaseNumber({ parentId:this.criminal }).then(res => {          //非诉文件夹排序
            //     this.accusingNumber = res.tag + '-' + res.number
            // })
        },
        typeSelect(type, index) {
            this.activeType = index;
            this.typeId = type.id;
        },
        yearSelect(year) {
            this.yearPopover = false;
            this.selectdYear = year.name;
            const { id: parentId } = year;
            this.form.parentId = parentId;
            this.$api.cases.getCaseNumber({ parentId }).then(res => {
                this.caseNumber = year.name + '-' + res.number
            })
        },
        async addNewYear() {

            if (!this.newYear) {
                this.$message.error("请输入创建的年份");
                return false;
            }

            if (String(this.newYear).length !== 4 || this.newYear < 1970) {
                this.$message.error("请输入合理的年份");
                return false;
            }

            if (this.years.some(y => y.name == this.newYear)) {
                this.$message.error("年份已存在");
                return false;
            }

            let params = {
                parentId: this.criminal,
                name: this.newYear
            }

            await this.$api.cases.createYearFolder(params).then(() => {
                this.newYear = ""
                this.$message.success("创建年份成功");
            })

            this.$api.cases.fastCreateEntry().then(res => {
                const { year } = res;
                this.$set(this, "years", year);
            })
        },
        // yearChange(){
        //     const {parentId} = this.form;
        //     let year = this.years.find(y=>y.id === parentId);
        //     if( year ){
        //         this.$api.cases.getCaseNumber({ parentId }).then(res => {
        //             this.caseNumber = year.name + '-' + res.number
        //         })
        //     }
        // },
        download() {
        },
        closed() {
            this.resetForm();
        },
        resetForm() {
            this.$set(this, "form", {
                parentId: "",
                human: "",
                name: ""
            })
            this.caseNumber = "";
            this.activeType = 0;
            this.criminal = this.criminals[0].id;
            this.folderName = "";
            this.selectdYear = "";
            this.$nextTick(() => {
                this.$refs.form.clearValidate();
            })
        },
        commit() {
            let { activeType: ac, form, folderName, criminal, nowCriminalIndex: NCIndex, typeId } = this;
           
            if (ac == 0) {
                let $api = this.$api.cases[["createDefendFolder", "createReportFolder", "createLitigationFolder"][NCIndex]]
                if (Array.isArray(form.name)) {
                    form.name = form.name.join(",")
                }
               
                if (NCIndex > 0) {
                    form.parentId = ''
                    form.parentId = criminal;
                  
                }
               
                if (this.selectdYear == '' && this.nowCriminalIndex == 0) {
                    store.commit('SETMSG', {
                        title: "提示",
                        type: 'error',
                        message: '请选择年份',
                    })
                    this.form.name = form.name.split(",")
                  
                    return
                }
                $api(form).then((res) => {
                    this.visible = false;
                    if (this.$route.name == "case") {            //在案卷中心直接新建，同路由下无法调整
                        let origin = window.location.origin;
                        window.location = origin + `/case?id=${res.id}`
                    } else {
                        this.$router.push({
                            name: "case",
                            query: {
                                id: res.id
                            },
                        })
                    }
                    this.$router.push({
                        name: "case",
                        query: {
                            id: res.id
                        },
                    })
                }).catch(err => {
                    this.form.name = form.name.split(",")
                 
                })
            } else {

                this.$api.cases.createFolder({
                    parentId: typeId,
                    name: folderName
                }).then((res) => {
                    this.visible = false;
                    if (this.$route.name == "case") {            //在案卷中心直接新建，同路由下无法调整
                        let origin = window.location.origin;
                        window.location = origin + `/case?id=${res.id}`
                    } else {
                        this.$router.push({
                            name: "case",
                            query: {
                                id: res.id
                            },
                        })
                    }
                }).catch(err =>{
                    this.form.name = form.name.split(",")
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.case-form {
    .type-list {
        display: flex;

        span {
            flex: 1;
            margin: 0 5px;
            text-align: center;
            border: none;
            cursor: pointer;
            font-size: 14px;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    .el-select {
        width: 100%;
    }

    ::v-deep .input-width.is-disabled input {
        background: #F0F0F0;
        color: #666666;
        border-color: #D0D0D0;
        padding: 0 12px;
        font-size: 14px;
    }

    ::v-deep .el-input__inner {
        padding: 0 12px;
        color: #333333;
        font-size: 14px;
    }

    .year-select-trigger {
        width: 100%;
        border: 1px solid #D0D0D0;
        border-radius: 8px;
        height: 36px;
        line-height: 36px;
        padding: 0 12px;
        cursor: pointer;
    }
}
</style>
<style lang="scss">
.el-select__tags {
    .el-tag.el-tag--info {
        background-color: #ebf8f5;
        border-color: #ebf8f5;
        color: #40B9A4;

        .el-tag__close {
            background-color: #b9ded7;
            color: #648881;
        }
    }
}

.el-select-dropdown__wrap {
    .el-select-dropdown__list {
        .el-select-dropdown__item {
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            padding: 0 12px 0 20px !important;

            &.selected,
            &:hover {
                background: #F5F7FA !important;
                color: #006AFF !important;
            }
        }
    }
}

.required-item-label {
    margin-bottom: 20px !important;

    .el-form-item__label {
        &::before {
            content: "*" !important;
            display: inline-block;
            color: #D81414 !important;
            margin-right: 4px;
        }
    }

    &.is-error {
        .el-form-item__content {
            input {
                border-color: #D81414 !important;
                padding: 0 12px;
                color: #333333;
                font-size: 14px;
            }

            .el-form-item__error {
                color: #D81414 !important;
            }
        }
    }
}

.year-select-box {
    width: 448px;

    .year-select-main {
        max-height: 110px;
        overflow-y: auto;
        padding: 6px 0;

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #CCC;
            border-radius: 5px;
        }

        &::-webkit-scrollbar-track {
            background-color: #FFF;
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: #CCC;
        }

        &::-webkit-scrollbar-thumb:active {
            background-color: #CCC;
        }

        .year-item {
            cursor: pointer;
            height: 34px;
            line-height: 34px;
            padding: 0 20px;

            &:hover {
                color: #006AFF;
                background-color: #F5F7FA
            }
        }
    }

    .year-create-box {
        height: 76px;
        padding: 16px;

        input {
            border-radius: 8px 0 0 8px;
            border-color: #D0D0D0;
            padding: 0 12px;
            font-size: 14px;
        }

        .el-input-group__append {
            border-color: #056BFD;
            border-radius: 0 8px 8px 0;
            padding: 0;
            min-width: 78px;
            background: #056BFD;
        }

        .el-button {
            width: 78px;
            height: 34px;
            background: #056BFD;
            border-radius: 0px 8px 8px 0px;
            font-weight: 500;
            color: #FFFFFF;
            font-size: 12px;
            border: none;
            margin: 0;
        }
    }
}
</style>