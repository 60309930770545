import Vue from 'vue'
import VueRouter from 'vue-router'
import layout from '@/components/layout'
Vue.use(VueRouter)

/**
 *  hasTitle: 是否有头部标题
 *  hideNav: 隐藏侧边导航栏
 */

const routes = [
  {
    path: '/home',
    redirect : '/'
  },
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/home'),
    meta: {
      title: '首页'
    }
  },
  {
    path: '/',
    component: layout,
    children: [
      // {
      //   path: 'home',
      //   redirect : '/'
      // },
      // {
      //   path: '',
      //   name: 'home',
      //   component: () => import(/* webpackChunkName: "home" */ '@/views/home'),
      //   meta: {
      //     title: '首页'
      //   }
      // },
      {
        path: 'privately',
        name: 'privately',
        component: () => import(/* webpackChunkName: "privately" */ '@/views/privately'),
        meta: {
          title: '私人文件夹',
          isogeny: 'home'
        }
      },
      {
        path: 'affair',
        name: 'affair',
        component: () => import(/* webpackChunkName: "affair" */ '@/views/affair'),
        meta: {
          title: '事务文件夹',
          isogeny: 'home'
        }
      },
      {
        path: 'personal',
        name: 'personal',
        component: () => import(/* webpackChunkName: "personal" */ '@/views/personal'),
        meta: {
          title: '个人信息',
          hasTitle: true,
          hideNav: false
        }
      }, 
	  {
        path: 'team',
        name: 'team',
        component: () => import(/* webpackChunkName: "team" */ '@/views/team'),
        meta: {
          title: '小组管理',
          hasTitle: true,
          hideNav: false
        }
      }, 
	  {
        path: 'order',
        name: 'order',
        component: () => import(/* webpackChunkName: "order" */ '@/views/order'),
        meta: {
          title: '我的订单',
          hasTitle: true,
          hideNav: false
        }
      }, 
	  {
        path: 'contact',
        name: 'contact',
        component: () => import(/* webpackChunkName: "help" */ '@/views/contact'),
        meta: {
          title: '联系我们'
        }
      }, 
	  {
        path: 'help',
        name: 'help',
        component: () => import(/* webpackChunkName: "help" */ '@/views/help'),
        meta: {
          title: '帮助中心'
        }
      }, 
	  {
        path: 'guide',
        name: 'guide',
        component: () => import(/* webpackChunkName: "help" */ '@/views/guide'),
        meta: {
          title: '新手指引'
        }
      }, 
	  {
        path: 'message',
        name: 'message',
        component: () => import(/* webpackChunkName: "message" */ '@/views/message'),
        meta: {
          title: '消息中心'
        }
      }, 
	  {
        path: 'message-detail',
        name: 'messageDetail',
        component: () => import(/* webpackChunkName: "message" */ '@/views/message/detail'),
        meta: {
          title: '消息详情',
          hasTitle: true,
          hideNav: false
        }
      }, 
	  {
        path: 'calendar',
        name: 'calendar',
        component: () => import(/* webpackChunkName: "calendar" */ '@/views/calendar'),
        meta: {
          title: '日历'
        }
      },
	  {
        path: 'forum',
        name: 'forum',
        component: () => import(/* webpackChunkName: "forum" */ '@/views/forum'),
        meta: {
          title: '业务交流'
        }
      },
	  {
        path: 'forum-detail/:id',
        name: 'forumDetail',
        component: () => import(/* webpackChunkName: "forum" */ '@/views/forum/detail'),
        meta: {
          title: '帖子详情',
          hasTitle: true,
          hideNav: false
        }
      },
	  {
        path: 'cooperation',
        name: 'cooperation',
        component: () => import(/* webpackChunkName: "calendar" */ '@/views/cooperation'),
        meta: {
          title: '案件协作'
        }
      }, 
	  {
        path: 'garbage',
        name: 'garbage',
        component: () => import(/* webpackChunkName: "garbage" */ '@/views/garbage'),
        meta: {
          title: '回收站'
        }
      }, {
        path: 'case',
        name: 'case',
        component: () => import(/* webpackChunkName: "case" */ '@/views/case'),
        meta: {
          title: '案件中心'
        }
      },
      {
        path: 'search/:name',
        name: 'search',
        component: () => import(/* webpackChunkName: "search" */ '@/views/search'),
        meta: {
          title: '全局搜索',
		  hasTitle: true,
          hideNav: false
        }
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/login'),
    meta: {
      title: '壹卷刑事辩护智能系统'
    }
  },
  {
    path: '/invitation/:code',
    name: 'invitation',
    component: () => import(/* webpackChunkName: "invitation" */ '@/views/invitation'),
    meta: {
      title: '小组邀请'
    }
  },
  {
    path: '/buy',
    name: 'buy',
    component: () => import(/* webpackChunkName: "buy" */ '@/views/buy'),
    meta: {
      title: '开通账号'
    }
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: () => import(/* webpackChunkName: "buy" */ '@/views/buy/agreement.vue'),
    meta: {
      title: '壹卷付费开通协议'
    }
  },
  {
    path: '/add-quota',
    name: 'addQuota',
    component: () => import(/* webpackChunkName: "buy" */ '@/views/buy/add-quota'),
    meta: {
      title: '添加名额'
    }
  },
  {
    path: '/renew-all',
    name: 'renewAll',
    component: () => import(/* webpackChunkName: "buy" */ '@/views/buy/renew-all'),
    meta: {
      title: '一键续费'
    }
  },
  {
    path: '/renew-personal',
    name: 'renewPersonal',
    component: () => import(/* webpackChunkName: "buy" */ '@/views/buy/renew-personal'),
    meta: {
      title: '个人续费'
    }
  },
  {
    path: '/marking',
    name: 'marking',
    component: () => import(/* webpackChunkName: "marking" */ '@/views/marking'),
    meta: {
      title: '阅卷系统'
    }
  },
  {
    path: '/graph',
    name: 'graph',
    component: () => import(/* webpackChunkName: "graph" */ '@/views/graph'),
    meta: {
      title: '思维导图'
    }
  }
]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: () => ({ y: 0 }),
})

export default router
