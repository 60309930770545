import request from '@/assets/javascript/request'

export default {
  /**
   * 获取小组成员
   */
  getGroupList(params) {
    return request.get('/group/users', params)
  },
  /**
   * 获取小组信息
   */
  getGroupInfo(params) {
    return request.get('/group/info', params)
  },
  /**
   * 添加成员
   */
  increaseUser(params) {
    return request.post('/group/addUser', params)
  },
  /**
   * 邀请组员
   */
  inviteUser(params) {
    return request.post('/group/inviteUser', params)
  },
  /**
   * 受邀用户加入小组
   */
  addInvitaUser(params) {
    return request.post('/group/addInvitaUser', params)
  },
  /**
   * 获取邀请链接信息
   */
  invitaLinkInfo(params) {
    return request.post('/group/invitaLinkInfo', params)
  },
  /**
   * 设置管理员
   */
  setManager(params) {
    return request.post('/group/setAdmin', params)
  },
  /**
   * 移除成员
   */
  removeUser(params) {
    return request.post('/group/removeUser', params)
  },

  /**
   * 小组改名
   */
  setGroupName(params) {
    return request.post('/group/setGroupName', params)
  },

  /**
   * 小组改名
   */
  addNewGroup(params) {
    return request.post('/group/addNewGroup', params)
  },
  
  /**
   * 小组删除
   */
  deleteGroup(params) {
    return request.post('/group/deleteGroup', params)
  },
}
