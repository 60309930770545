var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"button-container pointer not-select",class:{
    [`btn__${_vm.type}`]: true,
    'text': _vm.isText,
    'hover': _vm.isHover,
    'shadow': _vm.isShadow,
    'plain': _vm.plain,
    'disabled': _vm.disabled
  },style:(`width: ${_vm.w};height: ${_vm.h};border-radius: ${_vm.radius};font-size: ${_vm.fs}`),on:{"click":_vm.handleClick}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }