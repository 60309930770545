import request from '@/assets/javascript/request'

const header = {
    belong : "task"
}

export default {

    /**
     * 案件协作——列表
     */

    list(params) {
        return request.get('/task', params, header)
    },
    /**
     * 案件协作——发起案件协作
     */
    start(params) {
        return request.post('/task/start', params, header)
    },

    edit(params) {
        return request.post('/task/edit', params, header)
    },

    /**
     * 案件协作——详情（非本人发起）
     */
    detailNotSelf(params) {
        return request.get('/task/detail', params, header)
    },

    /**
     * 案件协作——详情（本人发起）
     */
    detailBySelf(params) {
        return request.get('/task/personalDetail', params, header)
    },

    /**
     * 案件协作——我的接单-详情
     */
    receiveDetail(params) {
        return request.get('/task/receiveDetail', params, header)
    },

    /**
     * 案件协作——我的发布
     */
    myIssue(params) {
        return request.get('/task/personalStart', params, header)
    },

    /**
     * 案件协作——我的接单
     */
    receive(params) {
        return request.get('/task/personalReceive', params, header)
    },

    /**
     * 案件协作——申请接单
     */
    apply(params) {
        return request.post('/task/apply', params, header)
    },

    /**
     * 案件协作——选择接单人
     */
    choose(params) {
        return request.post('/task/choose', params, header)
    },

    /**
     * 案件协作——撤销发起
     */
    cancel(params) {
        return request.post('/task/cancel', params, header)
    },

    /**
     * 案件协作——撤销申请接单
     */
    cancelApply(params) {
        return request.post('/task/cancelApply', params, header)
    },

    /**
     * 案件协作——投诉
     */
    complaint(params) {
        return request.post('/task/complaint', params, header)
    },
}
