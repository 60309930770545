import request from '@/assets/javascript/request'
export default {
  /**
   * 获取案件列表
   */
  getPapersList(params) {
    // return request.get('/marking/cases', params)
    return request.get('/papers/materials/list', params)
  },
  /**
   * 识别文字
   */
  getAccurateText(params) {
    return request.post('/ocr/accurate', params)
  },
  /**
   * 阅卷中心案件材料阅读
   */
  // getReviewList(params) {
  //   return request.get('/papers/review', params)
  // },
  // getReviewList(params) {
  //   return request.get('/papers/content/review', params)
  // },
  getReviewList(params) {
    // return request.get('/papers/read', params)
    return request.get('/api/v3/marking/content', params)
  },
  /**
   * 阅卷中心指定案件的文件
   */
  getFiles(params){
    // return request.get('/papers/files', params)
    return request.get('/api/v3/marking/files', params)
  },
  /**
   * 阅卷中心指定案件的文件
   */
  getArchivesFiles(params){
    return request.get('/papers/archives/files', params)
  },
  /**
   * 保存案件材料bak
   */
  savePapersMaterial(params){
    return request.get('/papers/save/material', params)
  },
  /**
   * 保存阅卷资料
   */
  saveMarking(parmas) {
    return request.post('/papers/evidence/toword', parmas)
    // return request.post('/papers/save/material', parmas)
  },
  /**
   * 保存案件材料bak
   */
  getSaveFiles(params){
    return request.get('/papers/files/material', params)
  },
  /**
   * 预览
   */
  browseMarking(params) {
    // return request.post('/papers/content/review', params)
    return request.post('/papers/review/evidence', params)
  },
  /**
   * 新增修改证据栏目列表
   */
  modifyColummn(params) {
    // return request.post('/papers/rename/colummn', params)
    return request.get('/api/v3/marking/content', params)
  },

  /**
   * 阅卷中心新增指控事实
   */
  addColumn(params){
    return request.post('/api/v3/marking/column', params)
  },

  /**
   * 删除证据栏目列表
   */
  deleteColummn(params) {
    return request.delete('/api/v3/marking/column', params)
    // return request.delete('/papers/rename/colummn', params)
  },
  /**
   * 移除指定案件材料
   */
  removeEvidence(params) {
    return request.post('/papers/remove/evidence', params)
  },

  // /**
  //  * 获取阅卷中心的证据栏目列表
  //  */
  // getColumnList(params) {
  //   return request.get('/papers/column/list', params)
  // },
  /**
   * 获取阅卷中心的证据栏目列表
   */
  getColumnList(params) {
    return request.get('/api/v3/marking/columnlist', params)
  },
  /**
   * 保存阅卷中心数据
   */
  savePaper(params) {
    return request.post('/papers/save/evidence', params)
  },

  /**
   * 阅卷中心案卷解锁
   */
  pagerUnlocked(params) {
    return request.post('/papers/locked', params)
  },

  /**
   * 阅卷中心点击打开PDF案件文件，上报一次
   */
  pagerLately(params) {
    // return request.post('/papers/files/lately', params)
    return request.post('/api/v3/marking/files/lately', params)
  },

  /**
   * 阅卷内容搜索
   */
  keyWordSearch(params){
    return request.get('/api/v3/marking/search', params)
  },

  /**
   * 阅卷中心案件材料保存（新版，直接保存整个html）
   */
  saveContent(params) {
    return request.post('/api/v3/marking/content', params)
  },

  /**
   * 阅卷中心预览
   */
  preview(params){
    return request.post('/api/v3/marking/reviewdoc', params)
  },
  
  /**
   * 阅卷中心生成文件
   */
  createdoc(params){
    return request.post('/api/v3/marking/createdoc', params)
  },

  /**
   * 阅卷中心 -- 生成质证意见 -- 旧文件
   */
  getOldFileList(params){
    return request.get('/api/v3/marking/files/material', params)
  }
  
}