import system from './module/system'
import order from './module/order'
import team from './module/team'
import message from './module/message'
import calendar from './module/calendar'
import garbage from './module/garbage'
import home from './module/home'
import cases from './module/cases'
import papers from './module/papers'
import coop from './module/cooperation'
import forum from './module/forum'
import guide from './module/guide'

export default {
  system,
  order,
  team,
  message,
  calendar,
  garbage,
  home,
  cases,
  papers,
  coop,
  forum,
  guide
}
