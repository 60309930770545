import request from '@/assets/javascript/request'

export default {
    /**
     * 获取视频列表
     */
    getList(params) {
        return request.get('/video', params)
    },
    /**
     * 获取视频详情
     */
    detail(params) {
        return request.get('/video/view', params)
    },
}
