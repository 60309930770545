<template>
  <div id="app">
		<router-view />
   

		<!-- 信息弹框 -->
		<msg-dialog />
		<message-model />
		<loading />
		<div class="company-footer-info">
		  粤ICP备20054168号 © 宋氏科技 版权所有
		</div>
  </div>
</template>

<script>
import messageModel from '@/components/message'
import msgDialog from '@/components/msg-dialog'
import loading from '@/components/loading'

export default {
  components: {
    messageModel,
    msgDialog,
    loading
  }
}
</script>

<style lang="scss">
#app {
	display: flex;
	height: 100%;
  height: -webkit-fill-available;
	//min-width: 1400px;
  // min-height: 100vh;
	overflow: hidden;
}
.company-footer-info {
  position: fixed;
  width: 100%;
  height: 30px;
  line-height: 30px;
  text-align: center;
  bottom: 0;
  z-index: 1000;
  background: #708096;
  font-weight: bold;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: 600;
}
</style>
