import request from '@/assets/javascript/request'

export default {
  /**
   * 体验版购买
   */
  experience(params) {
    return request.post('/order/experience', params)
  },
  /**
   * 付费版购买
   */
  pay(params) {
    return request.post('/order/pay', params)
  },
  /**
   * 添加名额
   */
  addAssign(params) {
    return request.post('/order/addAssign', params)
  },
  /**
   * 全体续费
   */
  renewAll(params) {
    return request.post('/order/groupRenew', params)
  },
  /**
   * 个人续费
   */
  renewPersonal(params) {
    return request.post('/order/assignRenew', params)
  },
  /**
   * 查看订单信息
   */
  getOrderInfo(params) {
    return request.get('/order/info', params)
  },
  /**
   * 查看订单状态
   */
  getOrderStatus(params) {
    return request.get('/order/payStatus', params)
  },
  /**
   * 我的订单列表
   */
  getOrderList(params) {
    return request.get('/order/personalList', params)
  },
  /**
   * 提交发票信息
   */
  fillInvoice(params) {
    return request.post('/order/invoice', params)
  },
  /**
   * 获取套餐属性
   */
  getConfig(params) {
    return request.get('/config/index', params)
  },
  /**
   * 点击购买统计
   */
  censusBuy(params) {
    return request.post('/order/click', params)
  },
  /**
   * 获取聚法VIP信息
   */
  vipList(params) {
    return request.get('/vip/list', params)
  }
}
