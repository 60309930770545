<template>
    <div class="pagination-container">
        <slot name="table" style="width: 100%" />
            <el-pagination
                v-if="pages && (pages.totalCount > pages.perPage || pageSizes[0]) && !pages.hide"
                :layout='(pages && pages.layout)||layout'
                :current-page="Number(pages.currentPage||'1')"
                :page-size="Number(pages.perPage||'10')"
                :total="pages.totalCount"
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"/>
    </div>
</template>

<script>
export default {
  props: {
      pages: {
          type: Object,
          default: null
      }
  },
  data() {
    return {
      pageSizes: [10, 20, 30, 40, 50, 100],
      layout: "total, sizes, prev, pager, next, jumper"
    }
  },
  created(){
      // console.log(this.pages)
  },
  methods: {
    handleCurrentChange(current) {
      this.$emit('currentChange', current)
    },
    handleSizeChange(current) {
      this.$emit('sizeChange', current)
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination-container {
  width: 100%;
  .el-pagination {
    display: flex;
    justify-content: flex-end;
    padding: 0;
    margin-top: 29px;
  }
}
</style>

