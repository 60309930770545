import { mapMutations } from 'vuex'

export default {
  methods: {
    ...mapMutations(['SETMSG']),
    // 开启成功消息弹框
    success({
      message,
      tip,
      duration,
      closeOnClickModal,
    }) {
      this.SETMSG({
        type: 'success',    // 消息类型
        message,            // 消息主体
        tip,                // 消息描述
        duration,           // 停留时间，为0时手动关闭
        closeOnClickModal,  // 是否点击黑幕关闭消息，默认false
      })
    },
    error({
      message,
      tip,
      duration,
      closeOnClickModal,
    }) {
      this.SETMSG({
        type: 'error',    // 消息类型
        message,            // 消息主体
        tip,                // 消息描述
        duration,           // 停留时间，为0时手动关闭
        closeOnClickModal,  // 是否点击黑幕关闭消息，默认false
      })
    },
  }
}
