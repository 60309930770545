import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState({
      userInfo: state => state.userInfo,
      curGroupId: state => state.curGroupId
    }),
    curGroup() {
      const { userInfo, curGroupId } = this

      if (!userInfo) return null

      let group = userInfo.groups.filter(v => v.groupId === curGroupId)[0];

      if( !group ){
        group = {     //新建小组跳转使用
          admin : 1,
          groupId : curGroupId
        }
      }
      return group
    }
  }
}