<template>
  <div
    :class="{ 'has-title': $route.meta.hasTitle }"
    class="layout-container">	
		<keep-alive>
		  <nav-layout v-if="!$route.meta.hideNav" />
		</keep-alive>
		<div class="main-content">
			<keep-alive>
			  <header-layout ref="header" />
			</keep-alive>
			
			<div class="title-wp flex ai_c" v-show="$route.meta.hasTitle">
			  <div @click="back" class="flex ai_c pointer">
			    <i class="back-icon" />
					  <span>返回</span>
			    <p class="ml14 back-title">{{ $route.meta.title }}</p>
			  </div>
			</div> 
			<div class="main-content-content">
				<div
				  :class="{ 'hide-nav': $route.meta.hideNav }"
				  class="full-wp main">
				  <router-view />
				</div>
				
			</div>	
			<div class="bottom-logo"></div>
		</div>	
  </div>
</template>

<script>
import headerLayout from './components/header-layout'
import navLayout from './components/nav-layout'
export default {
  components: {
    headerLayout,
    navLayout
  },
  provide(){
    return {
      headerRefresh : this.headerRefresh
    }
  },
  methods: {
    back() {
      const { path } = this.$route

      if (['/order', '/team', '/personal'].includes(path)) {
        this.$router.push('/')
      } else {
        this.$router.go(-1)
      }
    },
    headerRefresh(){
      this.$refs.header.refresh();
    }
  }
}
</script>

<style lang="scss" scoped>
	*{box-sizing: border-box;}
	.layout-container {
		min-height: 750px;
		width: 1400px;
		margin: 0 auto;
		height: 100vh;
		position: relative;
	  
		.main-content{
		  height: 100%;
		  transition: margin-left .28s;
		  margin-left: 150px;
		  position: relative;
		  padding: 96px 0 30px;
		  width: calc(100% - 150px);
		  .main-content-content{
			height: 100%;
			overflow-y: scroll;
			position: relative;
			// padding-bottom: 106px;
			&::-webkit-scrollbar{width:0;}
		  }
		  .title-wp{
			  margin-bottom: 16px;
			  padding: 0 16px;
			  font-size: 12px;
			  font-family: PingFangSC-Medium, PingFang SC;
			  font-weight: 500;
			  color: #9AAAB7;
			  line-height: 17px;
			  .back-icon{
				  width: 12px;
				  height: 12px;
				  background: url('~@/assets/image/global/icon_turnback.png') no-repeat;
				  background-size: 100%;
				  margin-right:3px;  
			  }
			  .back-title{
				  color: #60707D;
			  }
		  }
		}
		&.has-title {
		  .main-content{
			.main-content-content{
				
			}
		  }
		  .full-wp{		
			height: calc(100vh - 159px);  
		  }
		}
	}
	
	// .bottom-logo {
	//     position: absolute;
	//     bottom: 48px;
	//     left: 0;
	//     width: 100%;
	//     height: 38px;
	//     background: url("~@/assets/image/home/bg_img_shuiyin@3x.png") no-repeat;
	//     background-size: 100%;
	// }
</style>