import request from '@/assets/javascript/request'

export default {
  /**
   * 获取日历列表
   */
  getCalendarList(params) {
    return request.get('/schedule/index', params)
  },
  /**
   * 成员小组下拉
   */
  getUserSelect(params) {
    return request.get('/group/expireUsers', params)
  },
  /**
   * 侧边栏天记录
   */
  getJournalByDay(params) {
    return request.get('/schedule/side', params)
  },
  /**
   * 添加日程
   */
  addCalendar(params) {
    return request.post('/schedule/add', params)
  },
  /**
   * 删除日程
   */
  delCalendar(params) {
    return request.delete('/schedule', params)
  },
  /**
   * 编辑日程
   */
  editCalendar(params) {
    return request.post('/schedule/edit', params)
  },
  /**
   * 阅读日程
   */
  readCalendar(params) {
    return request.post('/schedule/read', params)
  },
  /**
   * 日程提醒
   */
  remind(params) {
    return request.get('/schedule/remind', params)
  },
}
