<template>
  <div @click.stop="onMask" v-if="msg && msg.length" class="message-container">
	<div @click.stop class="message-main" :class="msg && msg[0].type">
	  <div class="flex jc_b ai_c message-main-head" v-if="msg && msg[0].title">
		  <p class="main-messgse-title">{{ msg[0].title || ' '}}</p>
		  <i @click.stop="close" class="el-icon-close bold pointer no-select" />
	  </div>
	  <div class="flex jc_s message-main-body ai_c">
		  <i v-if="msg[0].type" :class="`icon__${msg[0].type}`" class="main-icon" />
		  <p class="main-messgse">{{ msg[0].message }}</p>
		  <span v-if="msg[0].tip" v-html="msg[0].tip" class="main-tip" />
	  </div>	  
	</div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  data() {
    return {
      // 定时关闭器
      closeTimer: null
    }
  },
  computed: {
    ...mapState({
      msg: state => state.msg
    })
  },
  watch: {
    msg(val) {
      if (val && val.length) {
        const { duration = 1000 } = val[0]
		
        document.body.style.overflow = 'hidden'
        document.removeEventListener('touchmove', this.mo, false)

        if (duration) {
          this.closeTimer = setTimeout(() => {
            this.REMOVEMSG()
            if (this.closeTimer) {
              clearTimeout(this.closeTimer)
              this.closeTimer = null
            }
          }, duration)
        }
      } else {
        document.body.style.overflow = ''
        document.removeEventListener('touchmove', this.mo, false)
      }
    }
  },
  methods: {
    ...mapMutations(['REMOVEMSG']),
    mo(e) {
    	e.preventDefault()
    },
    close() {
      this.REMOVEMSG()
      if (this.closeTimer) {
        clearTimeout(this.closeTimer)
        this.closeTimer = null
      }
    },
    onMask() {
      const { closeOnClickModal } = this.msg[0]

      if (closeOnClickModal) {
        this.close()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .message-container {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    left: 0;
    top: 0;
    z-index: 9999;
    .message-main {
      position: absolute;
      min-width: 439px;
      padding: 24px;
      background: #fff;
      text-align: center;
      left: 50%;
      top: 50%;  
	  border-radius: 8px;
      transform: translate(-50%,-50%);
	  &.success{		  
		  padding: 40px 63px 28px;
		  .message-main-body{
			flex-direction: column;			  
		  }
		  .main-icon{
			  width: 40px;
			  height: 40px;
			  margin: 0;
		  }
		  .main-messgse{
			  margin-top: 10px;
		  }
	  }
	  .message-main-head{
		  margin-bottom: 19px;
		  width: 100%;		  
		  .el-icon-close {
		    font-size: 14px;
		    width: 24px;
		    height: 24px;
		  	line-height: 22px;
		    border: 1px solid #DCDCDC;
		  	border-radius: 50%;
		  	text-align: center;
		  	color: #979797FF;
		  }
		  
		  .main-messgse-title{
			font-size: 18px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #111111;
			line-height: 24px;
		  }
	  }
	  .message-main-body{
		  width: 100%;
	  }
      .main-icon {
        width: 36px;
        height: 36px;
        margin-right: 10px;
        &.icon__success {
          background: url('~@/assets/image/global/popup_img_success.png') no-repeat;
          background-size: 100%;
        }
        &.icon__error {
          background: url('~@/assets/image/global/popup_img_error.png') no-repeat;
          background-size: 100%;
        }
      }
      .main-messgse {
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 28px;
        color: $primary_text;  
      }
      .main-tip {
        max-width: 100%;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		line-height: 20px;
        color: $secondary_text;
		text-align: center;
		margin-top: 8px;
      }
    }
  }
</style>
