import request from '@/assets/javascript/request'

export default {
  /**
   * 获取数据统计
   */
  getStat(params) {
    return request.get('/cases/data', params)
  },
  /**
   * 获取常办
   */
  getOften(params) {
    return request.get('/cases/often', params)
  },
  /**
   * 获取日历
   */
  getCalendar(params) {
    return request.get('/schedule/week', params)
  },
  /**
   * 获取消息
   */
  getMessage(params) {
    return request.get('/message/index', params)
  },
  /**
   * 私人目录列表
   */
  getPrivateDirectory(params) {
    return request.get('/personal', params)
  },
  /**
   * 创建私人文件夹
   */
  createPrivateFolder(params) {
    return request.post('/personal', params)
  },
  /**
   * 事务目录列表
   */
  getAffairDirectory(params) {
    return request.get('/affair', params)
  },
  /**
   * 创建事务文件夹
   */
  createAffairFolder(params) {
    return request.post('/affair', params)
  },
  /**
   * 私人面包屑
   */
  getPrivateBreadcrumb(params) {
    return request.get('/personal/breadcrumb', params)
  },
  /**
   * 事务面包屑
   */
  getAffairBreadcrumb(params) {
    return request.get('/affair/breadcrumb', params)
  },
  /**
   * 全局搜索
   */
  getSearch(params) {
    return request.get('/cases/search', params)
  },

  /**
   * 用户端--滚动公告
   */
  rollAll(params) {
    return request.get('/message/rollAll', params)
  },

  
  /**
   * 用户端--滚动公告详情
   */
  rollAllDetail(params) {
    return request.get('/message/detail', params)
  }


}
