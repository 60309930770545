import request from '@/assets/javascript/request'

export default {
  /**
   * 手机验证码登录
   */
  login(params) {
    return request.post('/login', params)
  },
  /**
   * 获取验证码
   */
  getCode(params) {
    return request.post('/sendCode', params)
  },
  /**
   * 获取个人信息
   */
  getUserInfo(params) {
    return request.get('/auth/info', params)
  },
  /**
   * 选择小组
   */
  chooseGroup(params) {
    return request.post('/auth/groupOn', params)
  },
  /**
   * 退出登录
   */
  logout(params) {
    return request.delete('/logout', params)
  },
  /**
   * 编辑用户信息
   */
  editUserInfo(params) {
    return request.post('/auth/update', params)
  },
  /**
   * 获取公共字典参数
   */
  getGlobalList(params) {
    return request.get('/down-list', params)
  },
  /**
   * 获取帮助列表
   */
  getHelpList(params) {
    return request.get('/help/index', params)
  },
  /**
   * 获取帮助信息
   */
  getHelpInfo(params) {
    return request.get('/help/config', params)
  },
  /**
   * 上传文件
   */
  upload(url, data) {
    return request.post(url, data, { 'Content-Type': 'multipart/form-data' })
  },
  /**
   * 微信登录
   */
  wxLogin(params) {
    return request.post('/wxLogin', params)
  },
  /**
   * 绑定信息
   */
  bindWx(params) {
    return request.post('/wxBind', params)
  },
  /**
   * 第三方登录
   */
  jufaLogin(params) {
    return request.post('/jufaLogin', params)
  },
  /**
   * 权限信息
   */
  forbidAuth(params) {
    return request.get('/forbidAuth', params, {belong:"task"})
  }
}
