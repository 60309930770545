import request from '@/assets/javascript/request'

export default {
  /**
   * 获取回收站
   */
  getGarbageList(params) {
    return request.get('/recycle/cases', params)
  },
  /**
   * 还原文件
   */
  reductionFile(params) {
    return request.put('/recycle/cases/file', params)
  },
  /**
   * 还原文件夹
   */
  reductionDirectory(params) {
    return request.put('/recycle/cases/directory', params)
  },
  /**
   * 删除文件
   */
  delFile(params) {
    return request.delete('/recycle/cases/file', params)
  },
  /**
   * 删除文件夹
   */
  delDirectory(params) {
    return request.delete('/recycle/cases/directory', params)
  }
}
