<template>
  <div
    @click="handleClick"
    :style="`width: ${w};height: ${h};border-radius: ${radius};font-size: ${fs}`"
    :class="{
      [`btn__${type}`]: true,
      'text': isText,
      'hover': isHover,
      'shadow': isShadow,
      'plain': plain,
      'disabled': disabled
    }"
    class="button-container pointer not-select">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'primary'
    },
    radius: {
      type: String,
      default: '4px'
    },
    fs: {
      type: String,
      default: '16px'
    },
    w: {
      type: String,
      default: '120px'
    },
    h: {
      type: String,
      default: '40px'
    },
    isHover: {
      type: Boolean,
      default: true
    },
    isText: {
      type: Boolean,
      default: false
    },
    isShadow: {
      type: Boolean,
      default: false
    },
    plain: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    // 点击
    handleClick() {
      if( !this.disabled ){
        this.$emit('click')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    &.text {
      display:inline-block;
      width: auto !important;
      height: auto !important;
      background: transparent !important;
      box-shadow: none !important;
    }
    &.btn__primary {
      color: #fff;
      background: $primary;
      &.hover:hover {
        background: $primary_deep;
      }
      &.plain {
        color: $primary;
        background: rgba($color: $primary, $alpha: .1);
        &.hover:hover {
          color: $primary_deep;
          background: rgba($color: $primary_deep, $alpha: .1);
        }
      }
      &.shadow {
        box-shadow: 0px 8px 25px 0px rgba($color: $primary, $alpha: .4);
        &.hover:hover {
          box-shadow: 0px 8px 25px 0px rgba($color: $primary_deep, $alpha: .4);
        }
      }
      &.text {
        color: $primary;
		font-size: 14px;
        &.hover:hover {
          color: $primary_deep;
        }
      }
    }
	&.btn__warning{
		color: #fff;
		background: $warning;
		&.hover:hover {
		  background: $warning_deep;
		}
		&.plain {
		  color: $warning;
		  background: rgba($color: $warning, $alpha: .08);
		  &.hover:hover {
		    color: $warning_deep;
		    background: rgba($color: $warning_deep, $alpha: .08);
		  }
		}
		&.shadow {
		  box-shadow: 0px 8px 25px 0px rgba($color: $warning, $alpha: .4);
		  &.hover:hover {
		    box-shadow: 0px 8px 25px 0px rgba($color: $warning_deep, $alpha: .4);
		  }
		}
		&.text {
		  color: $warning;
		  font-size: 14px;
		  &.hover:hover {
		    color: $warning_deep;
		  }
		}
	}
    &.btn__success {
      color: #fff;
      background: $success;
      &.hover:hover {
        background: $success_deep;
      }
      &.plain {
        color: $success!important;
        background: rgba($color: $success, $alpha: .1);
        &.hover:hover {
          color: $success_deep;
          background: rgba($color: $success_deep, $alpha: .1);
        }
      }
      &.shadow {
        box-shadow: 0px 8px 25px 0px rgba($color: $success, $alpha: .4);
        &.hover:hover {
          box-shadow: 0px 8px 25px 0px rgba($color: $success_deep, $alpha: .4);
        }
      }
      &.text {
        color: $success;
		font-size: 14px;
        &.hover:hover {
          color: $success_deep;
        }
      }
    }
    &.btn__danger {
      color: #fff;
      background: $danger;
      &.hover:hover {
        background: $danger_deep;
      }
      &.plain {
        color: $danger;
        background: rgba($color: $danger, $alpha: .1);
        &.hover:hover {
          color: $danger_deep;
          background: rgba($color:$danger_deep, $alpha: .1);
        }
      }
      &.shadow {
        box-shadow: 0px 8px 25px 0px rgba($color: $danger, $alpha: .4);
        &.hover:hover {
          box-shadow: 0px 8px 25px 0px rgba($color: $danger_deep, $alpha: .4);
        }
      }
      &.text {
        color: $danger;
		font-size: 14px;
        &.hover:hover {
          color: $danger_deep;
        }
      }
    }
    &.btn__info {
      color: #60707D;
      background: rgba(195, 207, 217, 0.20);
      border: 1px solid  $info;
      &.shadow {
        box-shadow: 0px 8px 25px 0px rgba($color: $info_deep, $alpha: .2);
      }
      &.text {
		font-size: 14px;
        border: none;
      }
    }
    &.disabled {
      cursor: not-allowed;
      opacity: 0.3;
    }
  }
</style>
