<template>
  <div>
    <el-dialog :visible.sync="visible" :close-on-click-modal="false" :append-to-body="true" width="664px"
      title="请选择案件文件夹上传" >

      <div class="year-tab flex wrap">
       
          <div class="item" :class="{ on: activeId === item.id }" v-for="item in yearList" @click="yearClick(item.id)"
            :key="item.id">
            {{ item.name }}
          </div>
       
      </div>

      <t-pagination :pages="pages" @currentChange="currentChange" @sizeChange="sizeChange">
        <div class="content" slot="table" v-loading="isLoading">

          <template v-if="caseList && caseList.length">
            <div class="content-head">
              <div class="content-title flex jc_b center">
                <div class="name">文件夹名称</div>
                <div class="checked">操作</div>
              </div>
            </div>
            <div class="content-body">
              <!-- <ul class="infinite-list" v-infinite-scroll="load" style="overflow:auto"> -->
                <div class="content-item  flex jc_b center" @click="archItemClick(item.id)"   v-infinite-scroll="load"
                  v-for="(item, index) in caseList" :key="index">
                  <div class="name">{{ item.name }}</div>
                  <div class="checked">选择</div>
                </div>
              <!-- </ul> -->

            </div>
          </template>
          <template v-else>
            <div class="nothing-box">
              <i class="not-icon " />
              <p class="not-tit">暂无内容</p>
            </div>
          </template>
          <!-- <div class="content-item" v-for="item in yearList" :key="item.id">
                  <div class="content-item_title" @click="yearClick(item.id)">
                      {{ item.name }}
                  </div>
                  <div class="content-item_list" v-if="activeId === item.id" @scroll="($event)=>scroll(item,$event)">
                      <template v-if="caseList && caseList.length">
                          <div
                              class="content-item_list_item"
                              v-for="it in caseList"
                              :key="it.id"
                              @click="archItemClick(item.id)">
                              <el-checkbox v-model="it.checked">
                              {{ it.name }}
                              </el-checkbox>
                          </div>
                      </template>
                      <div v-else class="nothing-box">
                          <i class="not-icon small" />
                      </div>
                  </div>
              </div> -->
        </div>
      </t-pagination>
      <!-- <div class="flex jc_e ai_c pt20">
            <t-btn @click="visible = false" class="bold" isText>取消</t-btn>
            <t-btn @click="confirm" w="78px" h="36px" class="bold ml30">确认</t-btn>
        </div> -->
    </el-dialog>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
import pagination from '@/mixins/pagination'
export default {
  mixins: [pagination],
  data() {
    return {
      visible: false,
      yearList: [],
      activeId: "",
      caseList: [],
      isLoading: false,
      itemTotal: 0
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapMutations(['CHANGE_YEAR_STATE']),
    init() {
      this.$api.cases.dossierList().then(res => {
        this.$set(this, "yearList", res.parent)
      })
    },
    scroll(year, $event) {
      let $target = $event.target;
      let childs = $target.querySelectorAll(".content-item_list_item");
      let readHeight = childs.length * 40;
      if ($target.scrollTop + 210 - readHeight == 0) {
        if (childs.length < this.itemTotal) {
          this.getList(year.id, parseInt(childs.length / 6) + 1)
        }
      }
    },
    yearClick(id) {
      if (id === this.activeId) {
        return;
      }
      this.getList(id);
    },
    archItemClick(id) {
      this.visible = false;
      console.log(this.$router)
      this.$router.push({
        name: "case",
        query: { id }
      })
      if (this.$router.history.current.name == 'case') {

        this.$router.go(0)
      }

      // this.caseList = this.caseList.map((it) => {
      //   it.checked = false;
      //   return it;
      // });
    },
    confirm() {
      let arr = this.caseList.filter((it) => it.checked);
      if (arr.length > 0) {
        this.visible = false;
        let id = arr[0].id;
        this.$router.push({
          name: "case",
          query: { id }
        })
      }
    },
    // 显示
    show() {
      this.visible = true;
    },
    getList(id = null, itemPerPage) {
      const { pageParams } = this;
      this.isLoading = true;
      let param = { parentId: id, ...pageParams };
      param.perPage = 6;
      param.pPerPage = 9999
      if (id) {
        param.pg = itemPerPage || 1;
      }
      this.$api.cases.dossierList(param).then(res => {
        this.isLoading = false;
        this.activeId = id || res.parent[0].id;
        if (!id) {
          this.pages = Object.assign({}, res.page, {
            layout: "prev, pager, next, jumper"
          })
        }
        if (res.parent && res.parent.length > 0) {
          this.yearList = res.parent
        }
        // if (!this.yearList || !this.yearList.length) {
        //     this.yearList = res.parent
        // }
        if (id && itemPerPage > 1) {
          let newCase = res.items.map((it) => {
            it.checked = false;
            return it;
          });
          this.caseList = this.caseList.concat(newCase)
        } else {
          this.caseList = res.items.map((it) => {
            it.checked = false;
            return it;
          });
        }

        if (res.pg) {
          this.itemTotal = res.pg.totalCount;
        }
        this.CHANGE_YEAR_STATE(false)
      })
    },
  },
  watch: {
    visible: {
      handler(newValue) {
        if (newValue) {
          this.getList();
        }
      },
      immediate: true,
    },
    "$store.state.curGroupId"() {         //小组切换刷新界面
      this.init();
    },
    "$store.state.yearChange"(val) {         //辩护年份增删修改响应
      if (val) {
        this.init();
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.content-body{
  height: 320px;
}
::v-deep .el-dialog__header .el-dialog__title {
  font-size: 16px !important;
}

.content {
  width: 100%;

  &-item {
    padding: 10px 16px;
    cursor: pointer;

    &:hover {
      background-color: #F5F7FA;

      .name {
        color: #006AFF;
      }
    }

    .name {
      flex: 1;
      font-weight: 500;
      color: #60707D;
      font-size: 14px;
      line-height: 20px;
      text-align: justify;
    }

    .checked {
      width: 50px;
      text-align: right;
      color: #006AFF;
    }
  }

  &-title {
    height: 44px;
    background: #F4F7FA;
    border-radius: 8px 8px 0px 0px;
    padding: 0 16px;

    .name {
      flex: 1;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #383D41;
    }

    .checked {
      width: 50px;
      text-align: right;
    }
  }

  &-body {
    max-height: 800px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0;
    }
  }
}

.year-tab {
  .item {
    width: 78px;
    height: 32px;
    background: rgba(0, 106, 255, 0.05);
    border: 1px solid #D1E4FF;
    text-align: center;
    font-weight: 500;
    color: #006AFF;
    font-size: 12px;
    border-radius: 16px;
    line-height: 30px;
    margin-bottom: 10px;
    position: relative;
    cursor: pointer;

    &:not(:first-child) {
      margin-left: 8px;
    }

    &.on,
    &:hover {
      background: #006AFF;
      border-color: #006AFF;
      color: #fff;

      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-top: 5px solid #006AFF;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
      }
    }
  }
}

.year-head {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding: 23px 24px 13px;

  .title {
    color: #111111;
    font-size: 16px;
  }
}
</style>