<!--
 * Description: VIP购买界面
-->

<template>
    <el-dialog
        class="purchase-dialog"
        @closed="closed"
        :visible.sync="visible"
		:show-close="false"
        :append-to-body="true"
        width="680px">
		<div class="dialog-header flex column center">
			<div class="flex ai_c title">
				<img  src="@/assets/image/global/img_purchase_title.png"/>
				<span>聚法会员</span>
			</div>
			<div class="tips">会员尊享更便捷的操作功能</div>
			<img class="close" src="@/assets/image/global/img_close_white.png" @click="closed"/>
		</div>
		<div class="dialog-body flex jc_b">
			<div class="left flex">
				<div class="left-lt">
					<div class="tit">批量下载</div>
					<div class="tips">
						批量下载EXCEL、WORD、PDF，各2000篇/次，批量下载案例6000篇/日
					</div>
				</div>
				<div class="left-rt">
					<div class="rt_top">
						<div class="tit">高级检索</div>
						<div class="tips">
							限次司法案例、法律法规、检查文书等自定义检索
						</div>
					</div>
					<div class="rt_down">
						<div class="tit">量刑检测</div>
						<div class="tips">
							无限次数的类案检索，生成量刑预测报告
						</div>
					</div>
				</div>
			</div>
			<div class="right">
				<div class="user-info flex jc_c">
					<div class="avatar">
						<img v-if="userInfo&&userInfo.avatar" :src="userInfo.avatar" class="user-avatar" />
						<img v-else class="user-avatar" src="~@/assets/image/home/home_top_img_head@3x.png" />  
					</div>
					<div class="info">
						<div class="info-box flex">
							<span class="username" v-if="userInfo">{{ userInfo.username||userInfo.phone }}</span>
							<img class="user-vip" src="~@/assets/image/home/home_top_img_vip2.png" v-if="userInfo.is_vip"/>  
							<img class="user-vip" src="~@/assets/image/home/home_top_img_vip.png" v-else/>  
						</div>
						<div class="info-box flex">
							<p class="txt" v-if="userInfo.is_vip">{{userInfo.jufavip_end_date.substr(0,10)}} 到期</p>
							<p class="txt" v-else>暂未开通聚法会员</p>
						</div>
					</div>
				</div>
				<div class="vip-title flex center">
					<p class="line"></p>
					<p class="title">壹卷用户优惠价</p>
					<p class="line"></p>
				</div>
				<div class="vip-list flex wrap">
					<div class="item flex column center" 
						:class="{on:vipInfo.id == item.id}"
						@click="changeVip(item)"
						v-for="(item,index) in vipList" :key="index">
						<p class="year">{{item.year}}年</p>
						<p class="amount">￥{{item.amount}}</p>
						<p class="init_amount">￥{{item.init_amount}}</p>
					</div>
				</div>
				<div class="goBuy" @click="buy">开通会员</div>
			</div>
		</div>
       <!-- <p class="dialog-title">开通聚法VIP服务即可享受</p>
        <div class="info-row">
            <div>
                <p>高级检索</p>
                <p>无限次司法案例、法律法规、检查文书等自定义检索</p>
            </div>
            <div>
                <p>量刑预测</p>
                <p>无限次数的类案检索，生成量刑预测报告</p>
            </div>
            <div>
                <p>批量下载</p>
                <p>批量下载EXCEL、WORD、PDF，各2000篇/次，批量下载案例6000篇/日</p>
            </div>
        </div>
        <div class="price-box">
            <p>壹卷用户优惠价</p>
            <p>{{amount}}元/年</p>
            <p>聚法官网价： 
                <span>1998元/年</span>
            </p>
        </div>
        <div class="split-line"></div>
        <div class="footer flex">
            <div class="text">
                订单金额 (元) :
            </div>
            <div class="money">
                {{amount}}
            </div>
            <div class="way">
                <img src="~@/assets/image/global/img_zhifubao@2x.png" />
                <div class="buy-button" @click="buy">前往支付</div>
            </div>
        </div>
  -->
	</el-dialog>
</template>

<script>
import { mapMutations } from 'vuex'
import message from '@/mixins/message'
export default {
    mixins: [message],
    name : "VIPPurchase",
    props : {
        image : {
            type : String,
            default : ""
        }
    },
    data(){
        return {
            visible : false,
            amount : 0,
			
			vipList:[],
			vipInfo:{}
        }   
    },
    mounted(){
		this.$api.order.vipList({}).then(res => {
			this.vipList = res.items
			this.vipInfo = this.vipList && this.vipList[0] || {}
		})
        this.$api.order.getConfig({
            tyep: 'pay',
            key: 'amount'
        }).then(res => {
            const amount = res.filter(v => v.key === 'amount')[0].value
            this.amount = Number(amount)
        })
    },
    methods: {
        ...mapMutations(['SETLOADING']),
		changeVip(item){
			this.vipInfo = item
		},
        show(){
            this.visible = true;
        },
        closed(){
            this.visible = false;
        },
        commit(){},
        buy(){
            const {curGroupId,userInfo,vipInfo} = this;
            let params = {
                groupId: curGroupId,
                phone: userInfo.phone,
                type: 4,
                userCount: "1",
                year: 1,
                assignId: "",
				vip_id:vipInfo.id
            }
            this.$api.order.renewPersonal(params).then(res => {
                window.open(res.payParams)
                this.SETLOADING({
                    title: '正在支付…',
                    message: '订单支付正在进行中，请稍等'
                })
                setTimeout(() => {
                    this.getOrderStatus(res.orderNo)
                }, 800)
            })
        },
        // 获取订单
        getOrderStatus(orderNo) {
            this.$api.order.getOrderStatus({ orderNo }).then(res => {
                if (res.pay_status === 1) {
                    this.getOrderStatus(orderNo)
                    return
                }

                if (res.pay_status === 2) {
                    this.success({
                        message: '支付成功',
						duration:1000
                    })
                    this.visible = false;
                    this.SETLOADING(null)
                } else {
                    this.error({
                        message: '支付失败',
                    })
                    this.SETLOADING(null)
                }

                // this.getOrderInfo(orderNo)
                // this.stepsCur = this.stepsCur + 1
                // this.SETLOADING(null)
            }).catch(() => {
                this.getOrderStatus(orderNo)
            })
        },
        // 获取订单信息
        getOrderInfo(orderNo) {
            this.$api.order.getOrderInfo({ orderNo }).then(res => {
                this.orderInfo = res
            })
        }
    }
}
</script>

<style lang="scss" scoped>

    .purchase-dialog {	
		::v-deep .el-dialog{
			border-radius: 28px;			
			width: 680px;
			background-image: url('~@/assets/image/global/img_purchase_bg.png');
			background-size: 100% 100%;
			background-color: transparent;
		}
		::v-deep .el-dialog__header{
			padding: 0;
		}
		::v-deep .el-dialog__body {
			padding: 14px;	
		}
		.dialog-header{
			height: 134px;
			position: relative;
			.close{
				width: 24px;
				height: 24px;
				position: absolute;
				right: 4px;
				top: 4px;
				cursor: pointer;
			}
			.title{
				font-size: 24px;
				font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
				font-weight: 800;
				color: #FFFFFF;
				line-height: 36px;
				
				img{
					width: 31px;
					height: 28px;
					margin-right: 7px;
				}
			}
			.tips{
				font-size: 14px;
				font-family: SourceHanSansCN-Medium, SourceHanSansCN;
				font-weight: 500;
				color: rgba(255, 255, 255, 0.6);
				line-height: 21px;
			}
		}
		.dialog-body{
			padding: 20px;
			border-radius: 28px;
			background: #FFFFFF;
			.left{
				.left-lt{
					width: 166px;
					height: 220px;
					margin-right: 4px;
					background-image: url('~@/assets/image/global/img_purchase_bg1.png');
					background-size: 100% 100%;
					padding: 12px 16px;
				}
				.left-rt{
					.rt_top{
						width: 166px;
						height: 108px;
						background-image: url('~@/assets/image/global/img_purchase_bg2.png');
						background-size: 100% 100%;
						margin-bottom:4px;
						padding: 12px 16px;
					}
					.rt_down{
						width: 166px;
						height: 108px;
						background-image: url('~@/assets/image/global/img_purchase_bg3.png');
						background-size: 100% 100%;
						padding: 12px 16px;
					}
				}
				.tit{
					font-size: 18px;
					font-family: YouSheBiaoTiHei;
					color: #006AFF;
					line-height: 23px;
				}
				.tips{
					font-size: 12px;
					font-family: PingFang-SC-Heavy, PingFang-SC;
					font-weight: 800;
					color: #9AAAB7;
					line-height: 17px;
					margin-top: 8px;
					text-align: justify;
				}
			}
			.right{
				width: 250px;
				.user-info{
					height: 48px;
					.avatar{
						width: 48px;
						height: 48px;						
						background: rgba(195,207,217,0.09);
						border-radius: 14px;
						.user-avatar{
							width: 100%;
							height: 100%;
						}
					}
					.info{
						width: 194px;
						margin-left: 8px;
						padding: 3px 0;
						.username{
							max-width: calc(100% - 70px);
							font-size: 16px;
							font-family: PingFang-SC-Heavy, PingFang-SC;
							font-weight: 800;
							color: $primary_title;
							line-height: 22px;
						}
						.user-vip{
							width: 65px;
							height:21px;
							margin-left:10px;
						}
						.info-box+.info-box{
							margin-top: 4px;
						}
						.txt{
							font-size: 12px;
							font-family: PingFang-SC-Heavy, PingFang-SC;
							font-weight: 800;
							color: #9AAAB7;
							line-height: 17px;
						}
					}
				}
				.vip-title{
					margin: 10px 0 8px;
					.line{
						width: 58px;
						height: 1px;
						background: rgba(209, 194, 172, 0.3);
					}
					.title{
						font-size: 12px;
						font-family: PingFang-SC-Heavy, PingFang-SC;
						font-weight: 800;
						color: #D1C2AC;
						line-height: 17px;
						margin: 0 8px;
					}
				}
				.vip-list{
					justify-content: center;
					.item{
						width: 80px;
						height: 90px;
						background: rgba(195,207,217,0.09);
						border-radius: 8px;
						margin-right: 5px;
						border: 1px solid transparent;
						cursor: pointer;
						margin-bottom: 5px;
						&:nth-child(3n){
							margin-right: 0;
						}
						.year{
							font-size: 12px;
							font-family: PingFang-SC-Heavy, PingFang-SC;
							font-weight: 800;
							color: #60707D;
							line-height: 17px;
						}
						.amount{
							font-size: 16px;
							font-family: PingFang-SC-Heavy, PingFang-SC;
							font-weight: 800;
							color: #006AFF;
							line-height: 22px;
							margin-top: 4px;
						}
						.init_amount{
							font-size: 12px;
							font-family: PingFang-SC-Heavy, PingFang-SC;
							font-weight: 800;
							color: #C3CFD9;
							line-height: 17px;
							margin-top: 7px;
							text-decoration: line-through;
						}
						&.on{
							border-color: #F4A93D;
							background-color: #FFFAF3;
							.year{
								color: #8D7552;								
							}
							.amount{
								color: #F4A93D;
							}
							.init_amount{
								color: #D5CABB;
							}
						}
					}
				}
				.goBuy{
					width: 250px;
					height: 40px;
					line-height: 40px;
					background: #F4A93D;
					border-radius: 8px;
					text-align: center;
					margin-top: 3px;
					color: #FFFFFF;
					font-size: 14px;
					font-family: PingFang-SC-Heavy, PingFang-SC;
					font-weight: 800;
					color: #FFFFFF;
					cursor: pointer;
				}
			}
		}

        .dialog-title {
            font-size: 22px;
            font-weight: 600;
            color: #056BFD;
            text-align: center;
        }
        .info-row {
            display: flex;
            padding: 40px 22px 23px;
            border-bottom: 1px solid #CCD5E6;
            > div {
                flex: 1;
                height: 70px;
                padding: 0 35px;
                border-right: 1px solid #CCD5E6;
                &:last-child {
                    border-right: none;
                    flex: 1.2;
                }
                > p {
                    transform: translateY(-5px);
                    &:first-child {
                        font-size: 20px;
                        font-weight: 600;
                        color: #333333;
                        margin-bottom: 8px;
                    }
                }
            }
        }
        .price-box {
            margin: 29px 57px;
            height: 250px;
            background: #ECF6FF;
            border-radius: 4px;
            border: 2px solid #CCD5E6;
            padding-top: 49px;
            text-align: center;
            > p {
                &:first-of-type {
                    font-size: 24px;
                    font-weight: 600;
                    color: #333333;
                    margin-bottom: 4px;
                }
                &:nth-child(2) {
                    font-size: 44px;
                    font-weight: 600;
                    color: #F4A93D;
                    margin-bottom: 14px;
                }
                &:last-child {
                    font-size: 20px;
                    font-weight: 400;
                    color: #999999;
                    span {
                        text-decoration:line-through;
                    }
                }
            }
        }
        .split-line {
            margin: 26px 57px;
            border-top: 1px dashed #E4E7ED;
            height: 0;
        }
        .footer {
            padding:0 58px;
            align-items: center;
            .text {
                font-size: 20px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #333333;
                margin-right: 8px;
            }
            .money {
                flex: 1;
                font-size: 28px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #F4A93D;
            }
            .way {
                display: flex;
                align-items: center;
                img {
                    height: 34px;
                    width: 34px;
                    margin-right: 20px;
                }
                .buy-button {
                    font-size: 16px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #FFFFFF;
                    width: 120px;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    background: #056BFD;
                    border-radius: 4px;
                    cursor: pointer;
                }
            }
        }
    }
</style>