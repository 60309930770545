<template>
	 <!-- :style="`left: ${left}px`" -->
  <div class="nav-container el-scrollbar">
    <el-scrollbar :native="false" class="nav-scroll">
        <div class="nav-scroll-box">
			<div class="logo cursor" @click="onLogo">
				<!-- <img src="~@/assets/image/global/home_top_img_logo.png" alt=""> -->
				<img src="~@/assets/image/global/home_top_img_logo@3x.png" alt="">
				<!-- <i  class="pointer" />  -->
			</div>
			
			<div class="nav-list-box">
				<template v-for="v in navList">
				    <div
				        @click="go(v.name)"
				        v-if="!v.permiss || curGroup && v.permiss === curGroup.groupType"
				        :key="v.name"
				        class="nav-item"
						:class=" isActive(v.name) ? 'active' : '' ">
				        <i :class="isActive(v.name) ? v.icon_on : v.icon_off" class="item-icon" />
				        <span :class="{ is_active: isActive(v.name) }" class="item-text">{{ v.label }}</span>
				    </div>
				</template>
			</div>
			<div @click="showPurchase" class="icon-vip" >
				<img src="@/assets/image/home/nav_icon_vip@2x.png" alt="">
			</div>
		</div>
		
		
      <!-- <template v-for="v in navList">
        <div
          @click="go(v.name)"
          v-if="!v.permiss || curGroup && v.permiss === curGroup.groupType"
          :key="v.name"
          class="nav-item"
        >
          <i :class="isActive(v.name) ? v.icon_on : v.icon_off" class="item-icon" />
          <span :class="{ is_active: isActive(v.name) }" class="item-text">{{ v.label }}</span>
        </div>
      </template> -->
    </el-scrollbar>
    <new-case ref="newCase"></new-case>
    <purchase ref="purchase"></purchase>
    <upload-case ref="uploadCase"></upload-case>
  </div>
</template>

<script>
import Purchase from '@/components/unitDialog/purchase';
import NewCase from '@/components/unitDialog/new-case';
import UploadCase from '@/components/unitDialog/upload-case.vue';

export default {
  name : "navLayout",
  components : {
    NewCase,
    Purchase,
    UploadCase
  },
  data() {
    const div = document.getElementById("app");
    return {
      innerWidth:
        div.style.width ||
        div.clientWidth ||
        div.offsetWidth ||
        div.scrollWidth,
      navList: [
        {
          label: "首页",
          name: "home",
          icon_on: "home_on",
          icon_off: "home_off"
        },
        {
          label: "新建案件",
          name: "newCase",
          icon_on: "anjian_off",
          icon_off: "anjian_off"
        },
        {
          label: "上传",
          name: "uploadCase",
          icon_on: "shangchuan_off",
          icon_off: "shangchuan_off"
        },
        // {
        //   label: "案件中心",
        //   name: "case",
        //   icon_on: "anjian_on",
        //   icon_off: "anjian_off"
        // },
        {
          label: "阅卷系统",
          name: "marking",
          icon_on: "yuejuan_on",
          icon_off: "yuejuan_off"
        },
        {
          label: "数据检索",
          name: "data",
          icon_on: "data_on",
          icon_off: "data_off",
          // permiss: 2
        },
        {
          label: "量刑预测",
          name: "sentencing",
          icon_on: "yuce_on",
          icon_off: "yuce_off",
          // permiss: 2
        },
        {
          label: "日历",
          name: "calendar",
          icon_on: "date_on",
          icon_off: "date_off"
        },
        // {
        //   label: '业务交流',
        //   name: 'business',
        //   icon_on: 'yewu_on',
        //   icon_off: 'yewu_off'
        // },
        // {
        //   label: '案件协作',
        //   name: 'cooperation',
        //   icon_on: 'xiezuo_on',
        //   icon_off: 'xiezuo_off'
        // },
        {
          label: "回收站",
          name: "garbage",
          icon_on: "garbage_on",
          icon_off: "garbage_off"
        }
      ]
    };
  },
  watch: {
    $route() {
      this.change();
    }
  },
  computed: {
    left() {
      let { innerWidth } = this;

      if (innerWidth <= 1400) return 0;

      console.info(innerWidth, 74);

      return (innerWidth - 1400) / 2;
    },
    isActive() {
      return val => {
        const { meta, name } = this.$route;

        return name === val || meta.isogeny === val;
      };
    }
  },
  methods: {
	  
	// 点击logo
	onLogo() {
	    const { path } = this.$route
	  
	    console.info(path)
	    if (path === '/') return
	  
	    this.$router.push({
	      name: 'home'
	    })
	},
    change() {
      const div = document.getElementById("app");
      const width =
        div.style.width ||
        div.clientWidth ||
        div.offsetWidth ||
        div.scrollWidth;

      if (this.innerWidth === width) return;

      this.innerWidth = width;
    },
    go(name) {
      if( name == "newCase" ){
        this.$refs.newCase.show();
        return ;
      }else if( name == "uploadCase" ){
        this.$refs.uploadCase.show();
        return ;
      }else if (["data", "sentencing"].includes(name)) {
        const type = name === "data" ? "data" : "lx";

        this.$api.system
          .jufaLogin({
            type
          })
          .then(res => {
            window.open(res.url, "_blank");
          });
        return;
      }
      if (name === this.$route.name) return;

      // if (name === "marking") {
      //   let link = this.$router.resolve({ name });
      //   window.open(link.href, "_blank");
      //   return;
      // }

      this.$router.push({
        name
      });
    },
    showPurchase(){
      this.$refs.purchase.show();
    }
  },
  created() {
    window.addEventListener("resize", this.change);
  },
  destroyed() {
    window.removeEventListener("resize", this.change);
  }
};
</script>

<style lang="scss" scoped>
	@import './nav.scss';
</style>