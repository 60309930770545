import axios from 'axios'
import store from '@/store'
import router from '@/router'

let requestUrls = [] // 正在请求中的接口
// 请求开始处理函数
const _startOfRequest = (url) => {
  requestUrls = requestUrls.concat(url)
}
// 请求结束处理函数
const _endOfRequest = (url) => {
  if (!url) {
    requestUrls = []

    return
  }

  requestUrls = requestUrls.filter(v => v !== url)
}

// 创建 axios 实例
let http = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 10 * 60 * 1000
})

http.defaults.headers['Content-Type'] = 'application/json;utf-8'

// 添加请求拦截器
http.interceptors.request.use(config => {
  const url = `${config.method}_${config.url}`
  const { headers: { belong } } = config;
  if ((belong && belong == "task") || (config.url.indexOf("down-list") >= 0 && config.params.type == "regions")) {
    config.baseURL = process.env.VUE_APP_TASK_URL
  } else if (belong && belong == "forum") {
    config.baseURL = process.env.VUE_APP_BASE_URL
  }

  delete config.headers.belong

  // if (requestUrls.includes(url)) {
  //     throw new Error('repeat url')
  // }
  _startOfRequest(url)

  if (localStorage.getItem('token')) {
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
  }

  // 请求发送前进行处理
  return config
}, error => {
  store.commit('SETMSG', {
    title: "请求失败",
    type: 'error',
    message: '请求失败',
    tip: error.message || ''
  })

  // 请求错误处理
  return Promise.reject(error)
})

// 添加响应拦截器
http.interceptors.response.use(response => {
  _endOfRequest(`${response.config.method}_${response.config.url}`)

  const { data, headers } = response

  // console.info(headers, 61)
  headers['refresh-token'] && localStorage.setItem('token', headers['refresh-token'])

  if (!data) {
    return ''
  }

  if (data.status === 'success') {
    return data.data
  } else {
    const errMessage = `${data.code} ${data.message}`

    if ([2001, 2002].includes(data.code)) {
      return Promise.reject(new Error(errMessage))
    }

    if ([401, 403, 420].includes(data.code)) {
      store.commit('SETUSERINFO', null)
      store.commit('SETCUR', null)
      localStorage.removeItem('token')
      router.push({
        name: 'login'
      })
    }

    // if( data.code == 2006 && window.location.href.indexOf("login") < 0 ){
    //   store.commit("SETAUTH",false)
    //   router.push({
    //     name: 'personal'
    //   })
    // }

    if (data.code !== 401 && data.code !== 420) {      //未登录不用提示
      store.commit('SETMSG', {
        title: "系统提示",
        type: 'error',
        message: data.message || '请求错误'
      })
    }

    return Promise.reject(new Error(errMessage))
  }
}, error => {

  if (error.response && [401, 403].includes(error.response.status)) {
    store.commit('SETUSERINFO', null)
    store.commit('SETCUR', null)
    localStorage.removeItem('token')
    router.push({
      name: 'login'
    })
  }

  if (error.message === 'repeat url') {
    console.warn('重复请求')
    return error
  }

  _endOfRequest()

  if (error.response.status !== 401) {      //未登录不用提示
    let message = "";
    // if (error.response && error.response.status == 420) {
    //   message = error.response.message || ''
    // } else {
    //   message = error.message || ''
    // }
    
    message = JSON.stringify(error)
    store.commit('SETMSG', {
      title: "系统提示",
      type: 'error',
      message: '请求失败',
      tip: message
    })
  }

  return Promise.reject(error)
})

/**
 * 创建统一封装过的 axios 实例
 * @return {AxiosInstance}
 */
export default {
  get(url, params = {}, headers = {}) {
    const options = {
      headers,
      params: { ...params, _t: new Date().getTime() }
    }
    return http.get(url, options)
  },
  post(url, params = {}, headers = {}) {
    const options = {
      headers
    }
    return http.post(url, params, options)
  },
  put(url, params = {}, headers = {}) {
    const options = {
      headers
    }

    return http.put(url, params, options)
  },
  delete(url, params = {}, headers = {}) {
    const options = {
      headers,
      params
    }

    return http.delete(url, options)
  }
}
