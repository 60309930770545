import request from '@/assets/javascript/request'

const header = {
    belong : "forum"
}

export default {

    /**
     * 业务交流（论坛）—— 列表
     */

    list(params) {
        return request.post('/v1/subject/all', params,header)
    },

    /**
     * 业务交流（论坛）—— 我的话题列表
     */
    mysubject(params) {
        return request.post('/v1/subject/mysubject', params,header)
    },

    /**
     * 业务交流（论坛）—— 话题详情
     */
    detail(params) {
        return request.post('/v1/subject/detail', params,header)
    },

    /**
     * 业务交流（论坛）—— 话题搜索
     */
    search(params) {
        return request.post('/v1/subject/search', params,header)
    },

    /**
     * 业务交流（论坛）—— 我的消息
     */
    message(params) {
        return request.post('/v1/subject/mymessage', params,header)
    },
     /**
     * 业务交流（论坛）—— 我的消息
     */
     messageComments(params) {
        return request.post('/v1/subject/mycomments', params,header)
    },
	/**
	 * 业务交流（论坛）—— 赞
	 */
    mylike(params) {
	    return request.post('/v1/subject/mylike', params,header)
	},

    /**
	 * 业务交流（论坛）—— 新我的消息
	 */
	 messageNews(params) {
	    return request.get('/v1/subject/news', params,header)
	},

    /**
     * 业务交流（论坛）—— 发起话题
     */
    create(params) {
        return request.post('/v1/subject/create', params,header)
    },

    /**
     * 业务交流（论坛）—— 发布话题评论
     */
    comment(params) {
        return request.post('/v1/subject/comment', params,header)
    },

    /**
     * 业务交流（论坛）—— 话题点赞
     */
    like(params) {
        return request.post('/v1/subject/like', params,header)
    },

    /**
     * 业务交流（论坛）—— 增加/移除收藏
     */
    collection(params) {
        return request.post('/v1/subject/collection', params,header)
    },

    /**
     * 业务交流（论坛）—— 评论列表
     */
    comments(params) {
        return request.post('/v1/subject/comments', params,header)
    },

    /**
     * 业务交流（论坛）—— 我的评论列表
     */
    mycomments(params) {
        return request.post('/v1/subject/mycomments', params,header)
    },

    /**
     * 业务交流（论坛）—— 我的收藏
     */
     mycollections(params) {
        return request.post('/v1/subject/mycollections', params,header)
    },
	/**
	 * 业务交流（论坛）—— 删除话题
	 */
	 delete(params) {
	    return request.post('/v1/subject/delete', params,header)
	},

    /**
     * 业务交流（论坛）—— 我的收藏
     */
    remove(params) {
        return request.post('/v1/subject',Object.assign({
            ...params,
            ...{
                action : "remove"
            }
        }) ,header)
    },

}
