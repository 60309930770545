import request from '@/assets/javascript/request'

export default {
  /**
   * 获取目录列表
   */
  getDirectory(params) {
    return request.get('/cases/directory', params)
  },
  /**
   * 创建文件夹
   */
  createFolder(params) {
    return request.post('/cases/directory', params)
  },
  /**
   * 创建年份文件夹
   */
  createYearFolder(params) {
    return request.post('/cases/directory/year', params)
  },
  /**
   * 重命名文件夹
   */
  renameByFolder(params) {
    return request.put('/cases/directory/rename', params)
  },
  /**
   * 重命名文件
   */
  renameByFile(params) {
    return request.put('/cases/file/rename', params)
  },

  /**
   * 删除名文件夹
   */
  deleteFolder(params) {
    return request.delete('/cases/directory', params)
  },
  /**
   * 删除名文件
   */
  deleteFile(params) {
    return request.delete('/cases/file', params)
  },
  /**
   * 获取文件夹不可见列表
   */
  getUsersDenyByFloder(params) {
    return request.get('/cases/directory/users-deny', params)
  },
  /**
   * 获取文件不可见列表
   */
  getUsersDenyByFile(params) {
    return request.get('/cases/file/users-deny', params)
  },
  /**
   * 获取文件夹可见列表
   */
  getUsersAllowByFloder(params) {
    return request.get('/cases/directory/users-allow', params)
  },
  /**
   * 获取文件可见列表
   */
  getUsersAllowByFile(params) {
    return request.get('/cases/file/users-allow', params)
  },
  /**
   * 设置文件夹不可见列表
   */
  setUsersDenyByFloder(params) {
    return request.put('/cases/directory/users-deny', params)
  },
  /**
   * 设置文件不可见列表
   */
  setUsersDenyByFile(params) {
    return request.put('/cases/file/users-deny', params)
  },
  /**
   * 设置文件夹可见列表
   */
  setUsersAllowByFloder(params) {
    return request.put('/cases/directory/users-allow', params)
  },
  /**
   * 设置文件可见列表
   */
  setUsersAllowByFile(params) {
    return request.put('/cases/file/users-allow', params)
  },
  /**
   * 设置文件夹常办
   */
  setOftenByFloder(params) {
    return request.put('/cases/directory/often', params)
  },
  /**
   * 设置文件常办
   */
  setOftenByFile(params) {
    return request.put('/cases/file/often', params)
  },
  /**
   * 取消文件夹常办
   */
  unoftenByFloder(params) {
    return request.put('/cases/directory/unoften', params)
  },
  /**
   * 取消文件常办
   */
  unoftenByFile(params) {
    return request.put('/cases/file/unoften', params)
  },
  /**
   * 获取案件编号
   */
  getCaseNumber(params) {
    return request.get('/cases/new-crime-number', params)
  },
  /**
   * 创建辩护罪名文件夹
   */
  createDefendFolder(params) {
    return request.post('/cases/directory/defend', params)
  },
  /**
   * 重命名辩护罪名文件夹
   */
  renameDefendFolder(params) {
    return request.put('/cases/directory/defend', params)
  },
  /**
   * 创建报案罪名文件夹
   */
  createReportFolder(params) {
    return request.post('/cases/directory/charge', params)
  },
  /**
   * 重命名报案罪名文件夹
   */
  renameReportFolder(params) {
    return request.put('/cases/directory/charge', params)
  },
  /**
   * 案件材料快捷列表
   */
  dossierList(params) {
    return request.get('/cases/directory/dossier-list', params)
  },
  /**
   * 创建非诉罪名文件夹
   */
  createLitigationFolder(params) {
    return request.post('/cases/directory/litigation', params)
  },
  /**
   * 重命名非诉罪名文件夹
   */
  renameLitigationFolder(params) {
    return request.put('/cases/directory/litigation', params)
  },
  /**
   * 获取辩护案件信息
   */
  getDefendInfo(params) {
    return request.get('/cases/defend/info', params)
  },
  /**
   * 辩护案件基础信息保存
   */
  saveBasics(params) {
    return request.put('/cases/defend/basic', params)
  },
  /**
   * 辩护案件当事人信息保存
   */
  saveHuman(params) {
    return request.put('/cases/defend/human', params)
  },
  /**
   * 辩护案件侦查阶段信息保存
   */
  saveExamine(params) {
    return request.put('/cases/defend/examine', params)
  },
  /**
   * 辩护案件起诉阶段信息保存
   */
  saveProsecution(params) {
    return request.put('/cases/defend/prosecution', params)
  },
  /**
   * 辩护案件一审信息保存
   */
  saveFirst(params) {
    return request.put('/cases/defend/trial/first', params)
  },
  /**
   * 辩护案件二审信息保存
   */
  saveSecond(params) {
    return request.put('/cases/defend/trial/second', params)
  },
  /**
   * 辩护案件一审重审信息保存
   */
  saveFirstRepeat(params) {
    return request.put('/cases/defend/trial/first-repeat', params)
  },
  /**
   * 辩护案件二审重审信息保存
   */
  saveSecondRepeat(params) {
    return request.put('/cases/defend/trial/second-repeat', params)
  },
  /**
   * 辩护案件信息保存
   */
  saveAppeal(params) {
    return request.put('/cases/defend/appeal', params)
  },
  /**
   * 获取工作日志
   */
  getWorkLogs(params) {
    return request.get('/cases/defend/worklog', params)
  },
  /**
   * 删除工作日志
   */
  delWorkLogs(params) {
    return request.delete('/cases/defend/worklog', params)
  },
  /**
   * 添加工作日志
   */
  createWorkLogs(params) {
    return request.post('/cases/defend/worklog', params)
  },
  /**
   * 编辑工作日志
   */
  editWorkLogs(params) {
    return request.put('/cases/defend/worklog', params)
  },
  /**
   * 上传文件
   */
  uoloadFile(params) {
    return request.post('/cases/file', params)
  },
  /**
   * 新建空白文档
   */
  createBlankFile(params) {
    return request.post('/cases/file/new', params)
  },
  /**
   * 新建模板文档
   */
  createTemplateFile(params) {
    return request.post('/cases/file/copy', params)
  },
  /**
   * 获取思维导图信息
   */
  getXmlInfo(params) {
    return request.get('/cases/file/xmid', params)
  },
  /**
   * 创建思维导图信息
   */
  createXml(params) {
    return request.post('/cases/file/xmid', params)
  },
  /**
   * 创建思维导图信息
   */
  editXml(params) {
    return request.put('/cases/file/xmid', params)
  },
  /**
   * 图片转pdf
   */
  image2pdf(params) {
    return request.post('/cases/file/image2pdf', params, {
      'content-type': 'multipart/form-data;utf-8;'
    })
  },
  /**
   * 头部面包屑
   */
  getBreadcrumb(params) {
    return request.get('/cases/directory/breadcrumb', params)
  },
  /**
   * 
   * 图片转PDF任务数
   */
  getImage2pdfTask(params) {
    return request.get('/cases/file/image2pdf-task-count', params)
  },
  /**
   * 
   * 文件夹设置密码
   */
  setDirPassword(params) {
    return request.put('/cases/directory/set-password', params)
  },
  /**
   * 
   * 文件设置密码
   */
  setFilePassword(params) {
    return request.put('/cases/file/set-password', params)
  },

  /**
   * 文件转换状态
   */
  transformStatu(params) {
    return request.get('/cases/file/transform-status', params)
  },

  /**
   * 文件转换
   */
  pdfToimage(params) {
    return request.post('/cases/file/pdf2image', params)
  },

  /**
   * 文件转换
   */
  fastCreateEntry(params) {
    return request.get('/cases/directory/fast-create-entry', params)
  },

    /**
   * 用户端--文书模板
   */
    getTemplateList(params) {
      return request.get('/template/list', params)
    },
}
