import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    msgDialog: null,
    msg: [],
    userInfo: null,
    loading: null,
    curGroupId: null,
    yearChange : false,
    authPass: true
  },
  mutations: {
    SETMSG(state, data = null) {
      if (!data) return
      state.msg.push(data)
    },
    REMOVEMSG(state) {
      if (state.msg && state.msg.length) {
        state.msg.shift()
      }
    },
    SETUSERINFO(state, data = null) {
      state.userInfo = data
    },
    SETMSGDIALOG(state, data = null) {
      state.msgDialog = data
    },
    SETLOADING(state, data = null) {
      state.loading = data
    },
    SETCUR(state, data = null) {
      state.curGroupId = data
    },
    SETAUTH(state, data = null) {
      state.authPass = data
    },
    CHANGE_YEAR_STATE(state,newState) {
      if( typeof newState == undefined ){
        state.yearChange = !state.yearChange
      }else{
        state.yearChange = newState
      }
    }
  }
})
