import Vue from 'vue'
import ElementUI from 'element-ui'
import App from './App.vue'
import router from './router'
import store from './store'
import api from './api'

import group from '@/mixins/group'
import { formatMoney } from '@/assets/javascript/util'

import TBtn from '@/components/button'
import Pagination from '@/components/pagination'
import  VueQuillEditor from 'vue-quill-editor'//调用编辑器
import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme

//sentry异常监控
// import * as Sentry from "@sentry/vue";
// import { BrowserTracing } from "@sentry/tracing";

// Sentry.init({
//     Vue,
//     dsn: "https://01dec83200ee40fda4e7b68b9636e5c6@o1176552.ingest.sentry.io/6274473",
//     integrations: [
//         new BrowserTracing({
//             routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//         tracingOrigins: ["localhost", "my-site-url.com", /^\//],
//     }),
//   ],
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

// 样式
import '@/assets/css/base.scss'
import '@/assets/css/element.scss'

Vue.component('TBtn', TBtn)
Vue.component('TPagination', Pagination)

Vue.filter('formatMoney', formatMoney)
Vue.use(VueQuillEditor)
Vue.use(ElementUI)
Vue.mixin(group)

Vue.config.productionTip = false
Vue.prototype.$api = api

function afterEach (transition) {
  // 设置页面title
  if (transition.meta.title) {
    document.title = transition.meta.title
  }
}

function beforeEach (to, from, next) {
  store.commit('SETMSGDIALOG', null)
  if( !['/marking'].includes(to.path) ){
    window.onbeforeunload = ()=>{}      //清除阅卷中心的刷新退出监听事件
  }

  const token = localStorage.getItem('token')

  console.log(to.path)
  if( !store.state.authPass && !['/personal', '/login'].includes(to.path) ){     //认证信息未通过，只能停留在个人信息页
    next("/personal")
  }else{
    if (['/login', '/buy','/agreement'].includes(to.path) || to.path.indexOf("invitation") > 0 ) {    //,'/'去掉首页的时候也进该流程，否则首页刷新会丢失userInfo
      if( ['/login'].includes(to.path) && token ){
        next('/home')
      }else{
        next()
      }
    } else {
      if (!token) {
        next('/login')
        return
      }
  
      if (store.state.userInfo) {
        next()
      } else {
        if( !['/login'].includes(to.path) ){
        // if( !['/login','/personal'].includes(to.path) && to.path !== "/" ){
          api.system.getUserInfo().then(res => {
            store.commit('SETUSERINFO', res)
            const cur = res.groups.filter(v => v.on)
            const curTeam = cur && cur.length ? cur[0].groupId : null
            store.commit('SETCUR', curTeam)
            if( res.realAuth !== 3 ){
              store.commit("SETAUTH",false);
              next("/personal")
            }else{
              // store.commit("SETAUTH",true);
              next()
            }
          }).catch(() => {
            next('/login')
          })
        }else if( to.path == "/" || to.path == "/login" ){
          next();
        }
      }
    }
  }
}

router.beforeEach(beforeEach)
router.afterEach(afterEach)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
